// Style przeznaczone dla nadmorzem

:root {
    --color-theme-black: #000000;
    --color-theme-white: #ffffff;
    --color-theme-dark-white: #f0f0f0;

    --color-theme-dark-grey: #797979;
    --color-theme-light-grey: #dfe0e2;
    --color-theme-grey: #ccc;
    --color-theme-green: #4dca81;

    --color-theme-primary: #2086c9;
    --color-theme-secondary: #fdbd22;
    --color-theme-background-primary: #eff4fb;
    --color-theme-background-secondary: #e3ebf6;

    --color-theme-font-primary: #101010;
    --color-theme-font-secondary: #000000;
    --color-theme-font-header: #0C3859;
    
    --color-theme-wolne-od-dzis: #4dca81;
    --color-theme-wolne-od-weekendu: #da680c;

    --color-theme-alert-danger: #e91a21;

    --color-theme-filter-primary: invert(47%) sepia(10%) saturate(4761%) hue-rotate(166deg) brightness(91%) contrast(88%);
    --color-theme-filter-secondary: invert(82%) sepia(50%) saturate(1469%) hue-rotate(338deg) brightness(101%) contrast(98%);
    --color-theme-filter-green: invert(67%) sepia(37%) saturate(614%) hue-rotate(92deg) brightness(95%) contrast(89%);

    --color-theme-box-shadow: unset;

    --cc-btn-primary-bg: #007aff !important;
	--cc-btn-primary-hover-bg: #1570d4 !important;
	--cc-btn-secondary-hover-bg: #bdbdbd !important;
	--cc-btn-border-radius: 4px;
	--cc-toggle-bg-on: #4e4e4e !important;
	--cc-toggle-bg-off: #dcdcdc !important;
	--cc-toggle-bg-readonly: #4e4e4e !important;
	--cc-cookie-category-block-bg: #f4f4f4 !important;

    --bs-primary-rgb: #2086c9;

}

h1 {
    color: var(--color-theme-primary);
}

header{
    border-bottom: 1px solid var(--color-theme-dark-white);
}
